import React, { useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const ViewCurrentAffairs = () => {


    const [randDep, setRandDep] = useState(0);
    const [ca, setCa] = useState([]);
    const [caAll, setCaAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        const userData = {
         
        }
        Axios.post(api_url + 'getCurrentAffairs', qs.stringify(userData))
            .then((response) => {
                setCa(response.data.data);
                setCaAll(response.data.data);
                setLoading(false);
            })
    }, [randDep]);


    const deleteCa = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteCurrentAffairs/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }




    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = caAll.filter(student => {
            if ((student.title.toLowerCase().includes(text.toLowerCase())) || (student.categoryName.toLowerCase().includes(text.toLowerCase()))) {
                return student;
            }
        });


        if (filteredData.length) {
            setCa([...filteredData]);
        }
        else {
            setCa([]);
        }
    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Current Affairs</h1>
                        <small>Current Affairs List</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link to="/AddCurrentAffairs" className="btn btn-success"><i className="fa fa-plus"></i>
                                            &nbsp;Add Current Affiars
                                        </Link>
                                    </div>
                                </div>




                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="row">

                                                     
                                                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                                                <input type="text" className="form-control" placeholder="search by title, category " onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {
                                            !loading ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Category</th>
                                                         
                                                            <th>Pdf Link</th>
                                                            <th>Pdf File</th>
                                                            <th>Pdf Date</th>
                                                    
                                                            <th>Created At</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            ca.length ?
                                                            ca.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>
                                                                           {value.title}
                                                                        </td>
                                                                        <td>{value.categoryName}</td>
                                                                      
                                                                        <td>                                                                    
                                                                            <a href={value.pdfLink} target='_blank'>
                                                                            {value.pdfLink}
                                                                            </a>
                                                                        </td>
                                                                        <td>      
                                                                            {value.pdfPhoto ?                                                               
                                                                            (<a href={value.pdfPhoto} target='_blank'>
                                                                              Pdf File
                                                                            </a>):null
                                                                             }
                                                                        </td>
                                                                                                                                        
                                                                        <td>{Moment(value.date).format("DD-MM-YYYY")}</td>                                                                     
                                                                    
                                                                        <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td style={{display:'flex'}}>
                                                                            <Link
                                                                                to={{ pathname: `/EditCurrentAffairs` }}
                                                                                state={{
                                                                                    id: value.id,  
                                                                                    categoryId: value.categoryId,                                                                              
                                                                                    title: value.title,  

                                                                                    pdfLink: value.pdfLink,  
                                                                                    pdfPhoto: value.pdfPhoto,  
                                                                                    date: value.date,                                                                             
                                    
                                                                                }}>
                                                                                <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                                                                </button>
                                                                            </Link>


                                                                          
                                                                            <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteCa(value.id) : ""}><i className="fa fa-trash-o" />
                                                                            </button>

                                                                        </td>
                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {ca.length} records found</td>
                                                        </tr>


                                                    </tbody>
                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '20%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading}
                                                    />
                                                </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ViewCurrentAffairs