import React, { useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';


const AddCategory2 = () => {

    const [className, setClassName] = useState();
    const [randDep, setRandDep] = useState(0);
    const [errors, setErrors] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(false);   

    useEffect(() => {
        const userData = {
        }
        Axios.post(api_url + 'getCategory2', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data);
            })
    }, [randDep]);

    const classNameChangeHandler = (event) => {
        setClassName(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            name: className,
        }

        Axios.post(api_url + 'addCategory2', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Created successfully', {
                        position: "top-center",
                    });
                    setClassName("");
                    setErrors([]);
                }
                else
                    if (resp.data.status == 'DUPLICATE')
                     {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                else
                    if (resp.data.status == 'V_ERROR')
                     {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data.message);
                    }
                setRandDep(Math.random());



            });




    };

    const deleteCategory = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteCategory2/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }

    return (
        <>

             <Header></Header>
             <SideMenu></SideMenu>
           
            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Category</h1>
                        <small>Add Category 2</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                                <ToastContainer />

                                <div className="panel-body">
                                    <form className="col-sm-12" onSubmit={onSubmit}>
                                        <div className="col-sm-6 form-group">
                                            <label>Category Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Name" name="className" required value={className} onChange={classNameChangeHandler} autoFocus />
                                            <p className='errorMessage'>{errors.name}</p>
                                        </div>

                            <div className="col-sm-12">  
                            {
                                !loading ? (                           
                                <button type='submit' className="btn btn-success">Save Category</button>) 
                                : 
                                (<div  className='loader'>
                                   <Circles
                                    height="60"
                                    width="60"
                                    color="#4fa94d"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={loading}
                                    />
                                    </div>)
                            }
                          </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}











                <section className="content" style={{ marginTop: -80 }}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group" style={{display:'flex',justifyContent:'space-between'}}>
                                        <h3>Existing Records</h3>


                              {classes.length < 1 ?(<button type='button' className="btn btn-success" onClick={()=>{setRandDep(Math.random)}}>Get Classes</button>):null}
                                         

                                    </div>
                                </div>
                                <div className="panel-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Name</th>
                                                    <th>Created At</th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    classes.length ?
                                                        classes.map((value, key) => {

                                                            return (<tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{value.name}</td>
                                                                <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                <td>

                                                                    {/* <Link
                                                to={{pathname: `/classes/edit/${value.id}`}}
                                                state={{
                                                    id: value.id,
                                                    name:value.name
                                                    }}>
                                                 <button type="button" className="btn btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                            </button>
                                            </Link> */}


                                                                    <Link
                                                                        to={{ pathname: `/EditCategory2` }}
                                                                        state={{
                                                                            id: value.id,
                                                                            name: value.name,                                                                        
                                                                        }}>
                                                                        <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                                                        </button>
                                                                    </Link>


                                                                    <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteCategory(value.id) : ""}><i className="fa fa-trash-o" />
                                                                    </button>
                                                                </td>
                                                            </tr>);
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan='5'>No record found!</td>
                                                        </tr>
                                                }


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> {/* /.content-wrapper */}

        </>
    )
}

export default AddCategory2