import React from 'react';
import { Link } from "react-router-dom";

const SideMenu = () => {
  return (
<div>
        <aside className="main-sidebar" >
            {/* sidebar */}
            <div className="sidebar" >
              {/* Sidebar user panel */}
              <div className="user-panel" >
                <div className="image pull-left" >
                  <img src="assets/dist/img/avatar5.png" className="img-circle" alt="User Image" />
                </div>
                <div className="info">
                  <h4>Welcome</h4>
                  <p>Admin</p>
                </div>
              </div>
              {/* sidebar menu */}
              <ul className="sidebar-menu" >
                <li className="active">
                 <Link to="/Welcome"><i className="fa fa-hospital-o" /><span>Dashboard</span></Link>
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-bars" /><span>Courses</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                  
                     <li>
                      <Link to="/AddCategory1">Category 1</Link>
                    </li>   

                    <li>
                      <Link to="/AddCategory2">Category 2</Link>
                    </li>   

                    <li>
                      <Link to="/AddCategory3">Category 3</Link>
                    </li>   

                    <li>
                      <Link to="/ViewCourses">Courses</Link>
                    </li>   


                  </ul>
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-check" /><span>Current Affiars</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                  
                     <li>
                      <Link to="/AddCategory4">Category</Link>
                    </li>                 
                    <li>
                      <Link to="/AddCurrentAffairs">Current Affiars</Link>
                    </li>   
                  </ul>
                </li>

              </ul>
            </div> {/* /.sidebar */}
          </aside>
    </div>
  )
}

export default SideMenu