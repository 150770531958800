import React, { useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const ViewCourses = () => {


    const [randDep, setRandDep] = useState(0);
    const [courses, setCourses] = useState([]);
    const [coursesAll, setCoursesAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        const userData = {
         
        }
        Axios.post(api_url + 'getCourses', qs.stringify(userData))
            .then((response) => {
                setCourses(response.data.data);
                setCoursesAll(response.data.data);
                setLoading(false);
            })
    }, [randDep]);


    const deleteCourse = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteCourse/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }




    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = coursesAll.filter(student => {
            if ((student.title.toLowerCase().includes(text.toLowerCase())) || (student.category1Name.toLowerCase().includes(text.toLowerCase())) || (student.category2Name.toLowerCase().includes(text.toLowerCase())) || (student.category3Name.toLowerCase().includes(text.toLowerCase()))) {
                return student;
            }
        });


        if (filteredData.length) {
            setCourses([...filteredData]);
        }
        else {
            setCourses([]);
        }
    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Courses</h1>
                        <small>Courses list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link to="/AddCourse" className="btn btn-success"><i className="fa fa-plus"></i>
                                            &nbsp;Add New Course
                                        </Link>
                                    </div>
                                </div>




                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="row">

                                                     
                                                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                                                <input type="text" className="form-control" placeholder="search by title, category " onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {
                                            !loading ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Course Title</th>
                                                            <th>Category 1</th>
                                                            <th>Category 2</th>

                                                            <th>Category 3</th>
                                                            <th>Pdf Link</th>
                                                            <th>Pdf File</th>
                                                            <th>Youtube Link</th>
                                                    
                                                            <th>Created At</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            courses.length ?
                                                            courses.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>
                                                                            <img src={
                                                                                value.coursePhoto ? 
                                                                                (value.coursePhoto) : "assets/dist/img/avatar.png"
                                                                            } className="border-green hidden-xs hidden-sm" style={{ width: 20, height: 20, borderRadius: '50%',marginRight:'5px' }} />
                                                                            {value.title}
                                                                        </td>
                                                                        <td>{value.category1Name}</td>
                                                                        <td>{value.category2Name}</td>

                                                                        <td>{value.category3Name}</td>
                                                                        <td>                                                                    
                                                                            <a href={value.pdfLink} target='_blank'>
                                                                            {value.pdfLink}
                                                                            </a>
                                                                        </td>
                                                                        <td>      
                                                                            {value.pdfPhoto ?                                                               
                                                                            (<a href={value.pdfPhoto} target='_blank'>
                                                                              Pdf File
                                                                            </a>):null
                                                                             }
                                                                        </td>
                                                                        <td>
                                                                        <a href={value.youtubeLink} target='_blank'>
                                                                            {value.youtubeLink}
                                                                            </a>
                                                                        </td>
                                                    

                                                                        <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td style={{display:'flex'}}>
                                                                            <Link
                                                                                to={{ pathname: `/EditCourse` }}
                                                                                state={{
                                                                                    id: value.id,  
                                                                                    category1Id: value.category1Id,  
                                                                                    category2Id: value.category2Id,  
                                                                                    category3Id: value.category3Id,  
                                                                                    
                                                                                    title: value.title,  
                                                                                    pdfLink: value.pdfLink,  
                                                                                    pdfPhoto: value.pdfPhoto,  
                                                                                    coursePhoto: value.coursePhoto,  

                                                                                    youtubeLink: value.youtubeLink,  
                                                                                    description: value.description,  
                                                                        
                                                                                }}>
                                                                                <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                                                                </button>
                                                                            </Link>


                                                                          
                                                                            <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteCourse(value.id) : ""}><i className="fa fa-trash-o" />
                                                                            </button>

                                                                        </td>
                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {courses.length} records found</td>
                                                        </tr>


                                                    </tbody>
                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '20%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading}
                                                    />
                                                </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ViewCourses