import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { Link } from "react-router-dom";

const Welcome = () => {

  const [courseCount, setCourseCount] = useState(0);
  const [caCount, setCaCount] = useState(0);

  useEffect(() => {
    const userData = {
    }
    // Axios.post(api_url + 'getCategory1Count', qs.stringify(userData))
    //     .then((response) => {
    //         setCategory1Count(response.data.data)
    //     })

        Axios.post(api_url + 'getDashboardCount', qs.stringify(userData))
        .then((response) => {
          setCourseCount(response.data.courseCount);
          setCaCount(response.data.currentAffairsCount);
        })

  
}, []);


  return (
    <div>

      <Header></Header>
      <SideMenu></SideMenu>


      <>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <form
              action="#"
              method="get"
              className="sidebar-form search-box pull-right hidden-md hidden-lg hidden-sm"
            >
              <div className="input-group">
                <input
                  type="text"
                  name="q"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="input-group-btn">
                  <button type="submit" name="search" id="search-btn" className="btn">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </form>
            <div className="header-icon">
              <i className="fa fa-tachometer" />
            </div>
            <div className="header-title">
              <h1> Dashboard</h1>
              <small> Dashboard features</small>
              <ol className="breadcrumb hidden-xs">
                <li>
                  <a href="<?php echo base_url(); ?>">
                    <i className="pe-7s-home" /> Home
                  </a>
                </li>
                <li className="active">Dashboard</li>
              </ol>
            </div>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">

          
            <Link to="/ViewCourses">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <div className="panel panel-bd cardbox">
                    <div className="panel-body">
                      <div className="statistic-box">
                        <h2><span className="count-number">{courseCount}</span>
                        </h2>
                      </div>
                      <div className="items pull-left">
                        <i className="fa fa-book fa-2x"></i>
                        <h4>Courses</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/ViewCurrentAffairs">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <div className="panel panel-bd cardbox">
                    <div className="panel-body">
                      <div className="statistic-box">
                        <h2><span className="count-number">{caCount}</span>
                        </h2>
                      </div>
                      <div className="items pull-left">
                        <i className="fa fa-check fa-2x"></i>
                        <h4>Current Affairs</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <a href="#">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <div className="panel panel-bd cardbox">
                    <div className="panel-body">
                      <div className="items pull-left">
                        <i className="fa fa-plus-square fa-2x" />
                        <h4>Tile 2</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <div className="panel panel-bd cardbox">
                    <div className="panel-body">
                      <div className="items pull-left">
                        <i className="fa fa-plus-square fa-2x" />
                        <h4>Tile 3</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <div className="panel panel-bd cardbox">
                    <div className="panel-body">
                      <div className="items pull-left">
                        <i className="fa fa-plus-square fa-2x" />
                        <h4>Tile 4</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>{" "}
          {/* /.content */}
        </div>{" "}
        {/* /.content-wrapper */}
      </>






    </div>
  )
}

export default Welcome