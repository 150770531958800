import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';

const AddCurrentAffairs = () => {

    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState("");

    const [title, setTitle] = useState("");
    const [pdfLink, setPdfLink] = useState("");
    const [pdfPhoto, setPdfPhoto] = useState("");

    const [date, setDate] = useState("");
    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const userData = {
        }
        Axios.post(api_url + 'getCategory4', qs.stringify(userData))
            .then((response) => {
                setCategory(response.data.data)
            })
    
    }, [randDep]);



    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('categoryId', categoryId);

        formData.append('title', title);
        formData.append('pdfLink', pdfLink);
        formData.append('pdfPhoto', pdfPhoto);
        formData.append('date', date);

        fetch(api_url + "addCurrentAffairs", {
            method: 'POST',
            body: formData
        }).then((response) => response.json())
            .then(resp => {
                setLoading(false);
                if (resp.status == 'OK') {
                    toast.success('Created successfully', {
                        position: "top-center",
                    });
                    setErrors([]);
                    setCategoryId("");
                
                    setTitle("");
                    setPdfLink("");
                    setPdfPhoto();
                    document.getElementById('pdfPhoto').value = null;

                    setDate("");           

                }
                else
                    if (resp.status == 'DUPLICATE') {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.status == 'V_ERROR') {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data);
                        }
                        else {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.message);
                        }
                setRandDep(Math.random());

            });
    }

    return (
        <div>
            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Current Affiars</h1>
                        <small>Add Current Affiars</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link className="btn btn-primary" to="/ViewCurrentAffairs"> <i className="fa fa-list" /> Current Affairs List </Link>
                                    </div>
                                </div>
                                <ToastContainer />
                                <div className="panel-body" >
                                    <form className="col-sm-12" onSubmit={onSubmit}>

                                        <div className="">

                                        <div className="col-sm-6 form-group">
                                                <label>Category<span className='required_field'>*</span></label>
                                                <select className="form-control" value={categoryId} required name="category" onChange={(event) => { setCategoryId(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        category.length ?
                                                        category.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.categoryId}</p>
                                        </div>

                                        <div className="col-sm-6 form-group">
                                                <label>Title<span className='required_field'>*</span></label>
                                                <input type="text" className="form-control" value={title} placeholder="Enter Title" onChange={(event) => { setTitle(event.target.value) }} />
                                                <p className='errorMessage'>{errors.title}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Pdf Link</label>
                                                <input type="text" className="form-control" value={pdfLink} placeholder="Enter Pdf Link" onChange={(event) => { setPdfLink(event.target.value) }} />
                                                <p className='errorMessage'>{errors.pdfLink}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>PDF</label>
                                                <input type="file" id='pdfPhoto' className="form-control"
                                                    onChange={(e) => setPdfPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                <p className='errorMessage'>{errors.pdfPhoto}</p>
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label>Date</label>
                                                <input type="date" className="form-control" value={date} onChange={(event) => { setDate(event.target.value) }} />
                                                <p className='errorMessage'>{errors.date}</p>
                                            </div>


                                         
                                          

                                        </div>
                                        <div className="col-sm-12 reset-button">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Save Current Affiars</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}




            </div> {/* /.content-wrapper */}

        </div>

    )
}

export default AddCurrentAffairs;