import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { useLocation, useNavigate } from "react-router-dom";

const UpdateCourse = () => {

    const location = useLocation();
    const id = location.state.id;
    const navigate = useNavigate();

    const [category1, setCategory1] = useState([]);
    const [category1Id, setCategory1Id] = useState(location.state.category1Id);


    const [category2, setCategory2] = useState([]);
    const [category2Id, setCategory2Id] = useState(location.state.category2Id);


    const [category3, setCategory3] = useState([]);
    const [category3Id, setCategory3Id] = useState(location.state.category3Id);


    const [title, setTitle] = useState(location.state.title);
    const [pdfLink, setPdfLink] = useState(location.state.pdfLink);
    const [pdfPhoto, setPdfPhoto] = useState(location.state.pdfPhoto);
    const [coursePhoto, setCoursePhoto] = useState(location.state.coursePhoto);

    const [youtubeLink, setYoutubeLink] = useState(location.state.youtubeLink);
    const [description, setDescription] = useState(location.state.description);

    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const userData = {
        }
        Axios.post(api_url + 'getCategory1', qs.stringify(userData))
            .then((response) => {
                setCategory1(response.data.data)
            })

        Axios.post(api_url + 'getCategory2', qs.stringify(userData))
            .then((response) => {
                setCategory2(response.data.data)
            })


        Axios.post(api_url + 'getCategory3', qs.stringify(userData))
            .then((response) => {
                setCategory3(response.data.data)
            })

    }, [randDep]);



    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();

        formData.append('id', id);
        formData.append('category1Id', category1Id);
        formData.append('category2Id', category2Id);
        formData.append('category3Id', category3Id);

        formData.append('title', title);
        formData.append('pdfLink', pdfLink);
        formData.append('pdfPhoto', pdfPhoto);
        formData.append('coursePhoto', coursePhoto);

        formData.append('youtubeLink', youtubeLink);
        formData.append('description', description);

        fetch(api_url + "UpdateCourse", {
            method: 'POST',
            body: formData
        }).then((response) => response.json())
            .then(resp => {
                setLoading(false);
                if (resp.status == 'OK') {
                    toast.success('Updated successfully', {
                        position: "top-center",
                    });

                    setErrors([]);

                    setCategory1Id("");
                    setCategory2Id("");
                    setCategory3Id("");

                    setTitle("");
                    setPdfLink("");
                    setPdfPhoto();
                    document.getElementById('pdfPhoto').value = null;

                    setCoursePhoto();
                    document.getElementById('coursePhoto').value = null;

                    setYoutubeLink("");
                    setDescription("");

                    navigate('/ViewCourses');

                }
                else
                    if (resp.status == 'DUPLICATE') {
                        toast.error('Cannot update try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.status == 'V_ERROR') {
                            toast.error('Cannot update try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data);
                        }
                        else {
                            toast.error('Cannot update try again', {
                                position: "top-center",
                            });
                            setErrors(resp.message);
                        }
                setRandDep(Math.random());

            });
    }

    return (
        <div>
            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Courses</h1>
                        <small>Update Course</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link className="btn btn-primary" to="/ViewCourses"> <i className="fa fa-list" />  Courses List </Link>
                                    </div>
                                </div>
                                <ToastContainer />
                                <div className="panel-body" >
                                    <form className="col-sm-12" onSubmit={onSubmit}>

                                        <div className="">

                                            <div className="col-sm-6 form-group">
                                                <label>Category 1<span className='required_field'>*</span></label>
                                                <select className="form-control" value={category1Id} required name="category1" onChange={(event) => { setCategory1Id(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        category1.length ?
                                                            category1.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.category1Id}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Category 2<span className='required_field'>*</span></label>
                                                <select className="form-control" value={category2Id} required name="category2" onChange={(event) => { setCategory2Id(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        category2.length ?
                                                            category2.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.category2Id}</p>
                                            </div>



                                            <div className="col-sm-6 form-group">
                                                <label>Category 3<span className='required_field'>*</span></label>
                                                <select className="form-control" value={category3Id} required name="category3" onChange={(event) => { setCategory3Id(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        category3.length ?
                                                            category3.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.category3Id}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Course Title<span className='required_field'>*</span></label>
                                                <input type="text" className="form-control" value={title} placeholder="Enter Title" onChange={(event) => { setTitle(event.target.value) }} />
                                                <p className='errorMessage'>{errors.title}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Pdf Link</label>
                                                <input type="text" className="form-control" value={pdfLink} placeholder="Enter Pdf Link" onChange={(event) => { setPdfLink(event.target.value) }} />
                                                <p className='errorMessage'>{errors.pdfLink}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Pdf File   {pdfPhoto ?                                                               
                                                    (<a href={pdfPhoto} target='_blank' style={{marginLeft:'10px'}}>
                                                        Pdf File
                                                    </a>):null
                                                        }</label>

                                                <div style={{ display: 'flex' }}>                                                
                                                    <input type="file" id='pdfPhoto' className="form-control"
                                                        onChange={(e) => setPdfPhoto(e.target.files[0])}
                                                        placeholder="file" />
                                                </div>
                                                <p className='errorMessage'>{errors.pdfPhoto}</p>
                                            </div>



                                            <div className="col-sm-6 form-group">
                                                <label>Thumbnail(Max Size 100kb)</label>

                                                <div style={{ display: 'flex' }}>
                                                    {coursePhoto ? (
                                                        <div>
                                                            <img src={coursePhoto} className='photoThumb' />
                                                        </div>
                                                    ) : null}
                                                    <input type="file" id='coursePhoto' className="form-control"
                                                        onChange={(e) => setCoursePhoto(e.target.files[0])}
                                                        placeholder="file" />
                                                </div>
                                                <p className='errorMessage'>{errors.coursePhoto}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <label>Youtube Link</label>
                                                <input type="text" className="form-control" value={youtubeLink} placeholder="Enter Youtube Link" onChange={(event) => { setYoutubeLink(event.target.value) }} />
                                                <p className='errorMessage'>{errors.youtubeLink}</p>
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label>Description</label>
                                                <input type="text" className="form-control" value={description} placeholder="Enter Course Description" onChange={(event) => { setDescription(event.target.value) }} />
                                                <p className='errorMessage'>{errors.description}</p>
                                            </div>



                                        </div>
                                        <div className="col-sm-12 reset-button">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Update Course</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}




            </div> {/* /.content-wrapper */}

        </div>

    )
}

export default UpdateCourse;