import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './components/Login';
import { createContext,useState, useEffect } from 'react';
import Welcome from './components/Welcome';
import AddCategory1 from './components/AddCategory1';
import UpdateCategory1 from './components/UpdateCategory1';
import AddCategory2 from './components/AddCategory2';
import UpdateCategory2 from './components/UpdateCategory2';
import AddCategory3 from './components/AddCategory3';
import UpdateCategory3 from './components/UpdateCategory3';
import AddCourse from './components/AddCourse';
import ViewCourses from './components/ViewCourses';
import UpdateCourse from './components/UpdateCourse';
import AddCategory4 from './components/AddCategory4';
import UpdateCategory4 from './components/UpdateCategory4';
import AddCurrentAffairs from './components/AddCurrentAffairs';
import ViewCurrentAffairs from './components/ViewCurrentAffairs';
import UpdateCurrentAffairs from './components/UpdateCurrentAffairs';

export const SchoolContext  = createContext();

function App() {
  const [adminName, setAdminName] = useState("");
  const [adminToken, setAdminToken] = useState("");

  
  return (
    <div className="App">      
      <div className="hold-transition sidebar-mini" >      
        <div className="wrapper">

            {/* <Header></Header>
            <SideMenu></SideMenu> */}

            <SchoolContext.Provider value={{adminName,setAdminName,adminToken,setAdminToken}}>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="Welcome" element={<Welcome />} />
          <Route path="AddCategory1" element={<AddCategory1 />} />
          <Route exact path="EditCategory1" element={<UpdateCategory1 />} />

          <Route path="AddCategory2" element={<AddCategory2 />} />
          <Route exact path="EditCategory2" element={<UpdateCategory2 />} />
          <Route path="AddCategory3" element={<AddCategory3 />} />
          <Route exact path="EditCategory3" element={<UpdateCategory3 />} />
         

          <Route path="AddCourse" element={<AddCourse/>} />
          <Route path="ViewCourses" element={<ViewCourses/>} />
          <Route path="EditCourse" element={<UpdateCourse />} />

          <Route path="AddCategory4" element={<AddCategory4 />} />
          <Route exact path="EditCategory4" element={<UpdateCategory4 />} />

          <Route path="AddCurrentAffairs" element={<AddCurrentAffairs />} />
          <Route path="ViewCurrentAffairs" element={<ViewCurrentAffairs />} />
          <Route path="EditCurrentAffairs" element={<UpdateCurrentAffairs />} />
      
        </Routes>
        </SchoolContext.Provider>

       </div>
      </div>  
  </div>
  );
}

export default App;
